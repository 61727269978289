<template>
  <div class="content-container">
    <span class="title-login">Înregistrare</span>
    <ul class="tabs">
      <li class="active">
        <router-link to="/register/fizic">
        <i class="fa fa-user"></i>
        Persoană fizică</router-link>
      </li>
      <li>
      <router-link to="/register/juridic">
        <i class="fa fa-building"></i>
        Persoană juridică
        </router-link>
      </li>
    </ul>
    <form @submit.prevent="submitForm" class="save_new_request">
      <div class="row">
        <div class="col-md-12">
          <div class="alert alert-info">
          Ulterior completării datelor veți primi un link de înregistrare care va fi valabil <strong>24 ore</strong>.
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group has-feedback">
          <label for="last_name" class="custom-label">Nume</label>
          <input v-model="first_name" id="last_name" type="text" class="form-control custom-input" data-name="last_name" value="" autocomplete="false" autocapitalize="false" name="last_name" autocorrect="false">
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group has-feedback">
          <label for="first_name" class="custom-label">Prenume</label>
          <input v-model="last_name" id="first_name" type="text" class="form-control custom-input" data-name="first_name" value="" autocomplete="false" autocapitalize="false" name="first_name" autocorrect="false">
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group has-feedback">
          <label for="email" class="custom-label">Parola</label>
          <input v-model="password" id="email" type="password" class="form-control custom-input" data-name="email" value="" autocomplete="false" autocapitalize="false" name="email" autocorrect="false">
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group has-feedback">
          <label for="email" class="custom-label">Email</label>
          <input v-model="email" id="confirm_email" type="email" class="form-control custom-input NO_PASTE" data-name="confirm_email" value="" autocomplete="false" autocapitalize="false" name="confirm_email" autocorrect="false">
          </div>
        </div>
        <div class="col-xs-12">
          <div class="checkbox">
          <label>
          <input required="" ref="checkbox" type="checkbox" name="holder_agreement2" value="1" oninvalid="this.setCustomValidity('Bifați condițiile de mai sus')" onchange="this.setCustomValidity('')" autocomplete="false" autocapitalize="false" autocorrect="false">
          Sunt de acord cu prelucrarea datelor personale conform <a href="https://www.conectx.net/termeni-si-conditii" target="_blank">Termenilor și condițiilor</a> și <a href="https://www.conectx.net/protectia-datelor-personale" target="_blank">Politicii de confidențialitate</a>.
          </label>
          </div>
        </div>
        <div class="col-md-6 col-xs-12 col-md-offset-3">
          <div class="form-group">
            <div class="text-center">
              <button type="submit" id="send_but_reg" class="submit-btn white-font has-preloader" value="submit">
                <div class="spinner">
                <i class="fa fa-circle-o-notch fa-spin fa-1x fa-fw"></i>
                </div>
                Trimite solicitare
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
  import { REGISTER } from '../api.js'
  import JWT from 'vuejs-jwt'

  export default {
    title: 'ConectX - Înregistrare',
    components: {
      JWT
    },
    data: () => ({
      first_name: '',
      last_name: '',
      email: '',
      password: ''
    }),
    methods: {
      async submitForm(e) {
        return
        // if(!this.$v.first_name.$invalid && !this.$v.last_name.$invalid &&
        //    !this.$v.email.$invalid && !this.$v.password.$invalid && this.$refs.checkbox.checked) {
          const responseRegisterStatus = await REGISTER(
            this.first_name,
            this.last_name,
            this.email,
            this.password
          ).then(data => {
            if(data.data._general === 'auth.user_Already_Exist') {
              this.$toastr.e('Utilizator existent', 'Inregistrare esuata');
              return
            }
            this.$toastr.Add({
              title: "Utilizator creat",
              msg: "Introduceti datele pentru autentificare",
              timeout: 3500
            })
            this.$router.push('/login')
          })
        // } else {
        //   this.$toastr.e('Datele au fost introduse incorect', 'Autorizare esuata');
        // }
      }
    },
    mounted() {
      
    }
  }
</script>

